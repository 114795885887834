import http from './httpService';
import { getJwt } from './authService';

const apiEndpoint = http.apiEndpoint;

http.setJwt(getJwt());

export function getEmployeeStats(date){
    return http.get(apiEndpoint+'/manager/employee-stats', {params : {date: date}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function getEmployeesWeekStats(days=null){
    return http.get(apiEndpoint+'/manager/employee-week-stats', {params : {days: days}})
    .then((response) => {
        return response.data
    }).catch((err) => {
        return err
    });
}

export function editAllTasks(data){
    return http.post(apiEndpoint+'/manager/employee-edit-multiple-tasks', {data: data})
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err;
    })
}

export function approveAllTasks(id){
    return http.post(apiEndpoint+'/manager/employee-approve-all-subtasks', {id: id})
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err;
    })
}