import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { updateTaskStatus, getEmployees } from '../../services/jobService';

export default class SubTaskCard extends Component{
    constructor(props){
        super(props)
    }

    state = {
        employees : {},
        someoneElseId: null,
        //someoneElseName: null,
    }
    async componentDidMount(){
        //let user =  JSON.parse(getCurrentUser());
        let resp =  await getEmployees();
        this.setState({employees: resp.data});
        console.log(resp);
    }

    handleAnswer = async (e, status, feedback, someoneElseId, someoneElseName) => {
        let activeColor = "#29b86d";
        //let activeColorNo = "#ee3124";
        let inActiveColor = "#ddd";
        let thisId = e.target.attributes.id.nodeValue
        let dataId = e.target.attributes.dataid.nodeValue;
        
        if(thisId == 'yes'+dataId){
            e.target.style.background = activeColor;
            document.getElementById("no"+dataId).style.background = inActiveColor;
        }else if(thisId == 'no'+dataId){
            e.target.style.background = activeColor;
            document.getElementById("yes"+dataId).style.background = inActiveColor;
        }
        
        const response = await updateTaskStatus(this.props.id, status, feedback, someoneElseId, someoneElseName)
        if(response.status == true){
            toast.success(response.message);
        }else{
            toast.error(response.message);
        }
    }
    
    onChange = (e) => {
        //console.log(e.target)
        let val = e.target.value;
        this.setState({'someoneElseId' : val});
        const employees = this.state.employees
        if(employees.length > 0){
            employees.map((obj)=>{
                if(obj.id == val){
                    this.setState({'someoneElseName' : obj.name});
                }
            });
        }
    }

    render(){
        const employees = this.state.employees
        var options = [];
        options.push(<option value="">Task done on the behalf of</option>)
        if(employees.length > 0){
            employees.map((obj)=>{
                options.push(<option value={obj.id}>{obj.name}</option>)
            });
        }
        return(
            <>
            <div className="card">
                <div className="card-body">
                    <h3>QUESTION {this.props.index}</h3>
                    <p>{this.props.task}</p>
                    <span className="yes left">
                        <button type="button" dataId={this.props.index} id={'yes'+this.props.index} onClick={(e) => this.handleAnswer(e, 1, "Task Completed", this.state.someoneElseId, this.state.someoneElseName)} 
                        className="btn btn-light">Yes</button>
                    </span>
                    <span className="no right">
                        <button type="button" dataId={this.props.index} id={'no'+this.props.index} onClick={(e) => this.handleAnswer(e, 0, "",  this.state.someoneElseId, this.state.someoneElseName)} 
                        className="btn btn-light">No</button>
                    </span>
                </div>
            </div>
            <span className="accordion">
                <div className="accordion-item">
                    <div id={"panelsStayOpen-collapse"+this.props.index} className="accordion-collapse collapse" aria-labelledby={"panelsStayOpen-heading"+this.props.index}>
                        <div className="accordion-body">
                               <select className="form-select task-select" aria-label="Default select example" onChange={this.onChange}>
                                  {options}
                                </select>
                                <button type="button" dataId={this.props.index} id={'submit'+this.props.index} className="btn btn-success" onClick={(e) => this.handleAnswer(e, 1, "",  this.state.someoneElseId, this.state.someoneElseName)}>Submit</button>
                        </div>
                    </div>
                    <span className="btn-down">
                        <button className="accordion-button collapsed accordion-header" id={"panelsStayOpen-heading"+this.props.index}  type="button" data-bs-toggle="collapse" data-bs-target={"#panelsStayOpen-collapse"+this.props.index} aria-expanded="false" aria-controls={"panelsStayOpen-collapse"+this.props.index}></button>
                    </span>
                </div>
            </span>
            </>
        )
        
    }
}