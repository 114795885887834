import { useHistory } from "react-router-dom";
function Header(props){
    const history = useHistory();
    const goBack = () => {
        if(props.url != undefined && props.url != ""){
            console.log(props.url);
            let previous_urls = localStorage.getItem('previousUrls');
            var previousUrls = JSON.parse(previous_urls);
            previousUrls.pop(previousUrls.lenth-1);
            localStorage.setItem('previousUrls', JSON.stringify(previousUrls));
            window.location.href = props.url;
        }else{
            console.log("goback")
            history.goBack()
        }
        
    }
    return (
        <header>
            <h1>{props.title}</h1>
            {props.isBack &&
                <div className="back-btn position-relative">
                    <a type="button" className="btn btn-light" onClick={goBack}>
                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 2L2 8L8 14" stroke="#323232" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Back
                    </a>
                </div>
            }
        </header>
    );
}

export default Header;